.links {
  position: fixed;
  bottom: 5px;
  right: 5px;
}

.version {
  position: fixed;
  left: 5px;
  bottom: 5px;
}