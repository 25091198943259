html, body {
  position: relative;
  height: 100%;
  margin: 0;
  width: 100%;
}

a {
  color: unset;
}
